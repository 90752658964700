.input-group {
  padding-top: 10px;
  display: flex;
  flex-direction: column; }

.input-control {
  width: 100% !important;
  box-shadow: 0 0 0 0 #00000000 !important;
  /* border-color: #00000000 !important; */
  border: none !important;
  border-radius: 0;
  border-bottom: 2px solid #c0bebe !important;
  transition: all 0.3s ease-in; }
  .input-control:focus {
    border-bottom: 3px solid var(--primary) !important; }
  .input-control.is-invalid {
    border-bottom: 3px solid var(--danger) !important; }

.was-validated .input-control:invalid {
  border-bottom: 3px solid var(--danger) !important; }

.input-control.is-valid, .was-validated .input-control:valid {
  border-bottom: 3px solid var(--success) !important; }

select.input-control, .was-validated select.input-control {
  background-position: center right calc(0.875em + 0.1875rem) !important; }

.input-label {
  padding-left: 5px;
  pointer-events: none; }
