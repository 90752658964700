.block-inline-toolbar-tool {
    left: 50%;
    transform: translate(-50%) scale(0);
    position: absolute;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
    z-index: 2;
    box-sizing: border-box;
  }
  
  .block-inline-toolbar-tool:after, .block-inline-toolbar-tool:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .block-inline-toolbar-tool:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px;
  }
  .block-inline-toolbar-tool:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #ddd;
    border-width: 6px;
    margin-left: -6px;
  }

  .block-inline-toolbar-tool .size-medium{
    font-size: 14px;
  }
  .block-inline-toolbar-tool .size-small{
    font-size: 12px;
  }