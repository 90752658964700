.font-sizes .size-0{
font-size: 11px;
}
.font-sizes .size-1{
    font-size: 13px;
}
.font-sizes .size-2{
    font-size: 15px;
}
.font-sizes .size-3{
    font-size: 17px;
}