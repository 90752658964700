.common-block-container{
    position: relative;
    user-select: none;
}
.common-block-container:hover{
   border: dashed 1px blue;
}
.common-block-container .edit-options{
    display: none;
    position: absolute;
    top:6px;
    right: 6px;
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}
.common-block-container:hover .edit-options{
    display: inline;
}
.common-block-container .edit-options button{
    user-select: initial;
    pointer-events: initial;
}