.sms-editor-wrapper{
    overflow-y:auto;
    background-color: rgb(242, 242, 242);
}
.sms-editor-wrapper .RichEditor-root{
    min-height: 150px;
}
.sms-editor{
    float: right;
    width: 703px;
    max-width: 703px;
    min-width: 703px;
    margin: 10px;
    min-height:200px;
  }